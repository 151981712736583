var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"tab-item g-df-c"},[(_vm.item.img)?_c('img',{attrs:{"src":_vm.item.img,"alt":""}}):_vm._e(),(
      (_vm.item.iconClass || _vm.iconClass) &&
        (_vm.item.iconPosition === 'left' || _vm.iconPosition === 'left')
    )?_c('i',{staticClass:"iconfont",class:[
      _vm.item.iconClass ? _vm.item.iconClass : '',
      _vm.iconPosition ? _vm.iconPosition : '',
      _vm.iconClass ? _vm.iconClass : ''
    ]}):_vm._e(),_c('span',{class:{ new: _vm.item.new }},[_vm._v(_vm._s(_vm.item.name || _vm.item.text))]),(
      (_vm.item.iconClass || _vm.iconClass) &&
        (_vm.item.iconPosition === 'right' || _vm.iconPosition === 'right')
    )?_c('i',{staticClass:"iconfont",class:[
      _vm.item.iconClass ? _vm.item.iconClass : '',
      _vm.iconPosition ? _vm.iconPosition : '',
      _vm.iconClass ? _vm.iconClass : ''
    ]}):_vm._e(),(_vm.item.tabChecked)?[_c('section',{staticClass:"g-poa custom-checked",class:{ 'custom-checked-active': _vm.isActive }},[(_vm.item.tabChecked)?_c('i',{staticClass:"g-poa g-icon-success"}):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }