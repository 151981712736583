/**
 * 跳淘宝
 * @param itemid 数字|字符串
 * @return 返回value的类型
 * @category 工具Util
 * @example
 * window.open 打开淘宝详情
 * ```ts
 * openTaobao(12322)
 * ```
 */
export function openTaobao(itemid: number | string): void {
  window.open(backTaobao(itemid))
}

/**
 * 返回淘宝链接
 * @param itemid 数字|字符串
 * @return 淘系url
 * @category 工具Util
 * @example
 * 加密itemid
 * ```ts
 * backTaobao('R7JB2UoGaX7t6bbckx9AzFDJ-XQWo4eH00JWraykt3')
 * // => https://uland.taobao.com/item/edetail?id=R7JB2UoGaX7t6bbckx9AzFDJ-XQWo4eH00JWraykt3
 * ```
 * 非加密itemid
 * @example
 * ```ts
 * backTaobao('3123122')
 * // => https://detail.tmall.com/item.htm?id=3123122
 * ```
 */
export function backTaobao(itemid: number | string): string {
  if (isJmItemid(itemid)) {
    return 'https://uland.taobao.com/item/edetail?id=' + itemid
  } else {
    return 'https://detail.tmall.com/item.htm?id=' + itemid
  }
}

/**
 * 截取B段itemid
 * @param itemid 数字|字符串
 * @return B段itemid
 * @category 工具Util
 * @example
 * ```ts
 * interceptItemid('R7JB2UoGaX7t6bbckx9AzFDJ-XQWo4eH00JWraykt3')
 * // => 'XQWo4eH00JWraykt3'
 * ```
 */
export function interceptItemid(itemid: number | string) {
  if (isJmItemid(itemid)) {
    return (itemid as string).substring((itemid as string).indexOf('-') + 1)
  }
  return itemid
}

/**
 * 是否是加密itemid
 * @param itemid 数字|字符串
 * @return B段itemid
 * @category 工具Util
 * @example
 * ```ts
 * interceptItemid('R7JB2UoGaX7t6bbckx9AzFDJ-XQWo4eH00JWraykt3')
 * // => 'XQWo4eH00JWraykt3'
 * ```
 */
export function isJmItemid(itemid: number | string) {
  return /[A-Za-z0-9]{13,25}-[A-Za-z0-9]{13,25}/.test(itemid as string)
}
