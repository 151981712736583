export const routeLoadingConfig = {
  promoteRecord: {
    loading: {
      className: 'g-po-top'
    }
  },
  default: {
    timer: {
      hide: true
    }
  }
}
