import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  Route,
  RouteConfig,
  RouterOptions,
  ErrorHandler,
  RawLocation
} from 'vue-router/types/router'

import { appendResourceToHeadElement } from '@globalUtils/cdn'
import { pendingRequest } from '@/http/index'
const isProduction = process.env.NODE_ENV === 'production'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function(
  location: RawLocation,
  onComplete?: Function,
  onAbort?: ErrorHandler
) {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  return (originalPush.call(this, location) as any).catch((error: any) => error)
}

// dynamic import => webpack 3
// import(/* webpackChunkName: "index" */ '@/views/Index/Index.vue')

// ts-loader => webpack 2
/*
require.ensure(
  ['@/views/Index/Index.vue'],
  () => require('@/views/Index/Index.vue'),
  'index'
)
 */

const titleSuffix = '多兔数据魔盘-更懂淘客的搜索引擎（duotu.pro）'

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: '首页',
      showSidebar: true,
      // showSidebarAdvisory: true,
      // showSidebarIconTop: true,
      openSidebarHover: true,
      hideSearch: true,
      hideSecondNavTab: true,
      headerNavActiveIndex: 0,
      openHoverHeader: true
    },
    component: () =>
      require.ensure(
        ['@/views/Index/Index.vue'],
        () => require('@/views/Index/Index.vue'),
        'index'
      )
  },
  {
    path: '/products',
    name: 'Products',
    meta: {
      title: '全网商品',
      showSidebar: true,
      showChart: true,
      secondNavActiveIndex: 0,
      headerNavActiveIndex: 1,
      keepSearchContent: true,
      showSecondNavTabGatherChannel: true
    },
    component: () =>
      require.ensure(
        ['@/views/Products/Index.vue'],
        () => require('@/views/Products/Index.vue'),
        'products'
      )
  },
  {
    path: '/sale-rank',
    name: 'SaleRank',
    meta: {
      title: '销量榜单',
      showChart: false,
      showSidebar: true,
      headerNavActiveIndex: 2,
      secondNavActiveIndex: 1,
      showSearchGatherChannel: true
    },
    component: () =>
      require.ensure(
        ['@/views/SaleRank/Index.vue'],
        () => require('@/views/SaleRank/Index.vue'),
        'sale-rank'
      )
  },
  {
    path: '/plugs',
    name: 'Plugs',
    meta: {
      title: '多兔淘客插件',
      showSidebar: true,
      showSidebarAdvisory: true,
      // showSidebarIconTop: true,
      // openSidebarHover: true,
      hideSearch: true,
      hideSecondNavTab: true,
      headerNavActiveIndex: 3
      // openHoverHeader: true
    },
    component: () =>
      require.ensure(
        ['@/views/Plugs/Index.vue'],
        () => require('@/views/Plugs/Index.vue'),
        'plugs'
      )
  },
  {
    path: '/course',
    name: 'Course',
    meta: {
      title: '插件教程',
      showSidebar: true,
      showSidebarAdvisory: true,
      // showSidebarIconTop: true,
      // openSidebarHover: true,
      hideSearch: true,
      hideSecondNavTab: true,
      headerNavActiveIndex: 3
      // openHoverHeader: true
    },
    component: () =>
      require.ensure(
        ['@/views/Course/Index.vue'],
        () => require('@/views/Course/Index.vue'),
        'course'
      )
  },
  {
    path: '/promote-record',
    name: 'PromoteRecord',
    meta: {
      title: '推广记录',
      showSidebar: true,
      headerNavActiveIndex: 1,
      secondNavActiveIndex: 0,
      navRouterName: 'products',
      showSecondNavTabGatherChannel: true
    },
    component: () =>
      require.ensure(
        ['@/views/PromoteRecord/Index.vue'],
        () => require('@/views/PromoteRecord/Index.vue'),
        'promote-record'
      )
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: '网页不存在'
    },
    component: () =>
      require.ensure(
        ['@/views/NotFound/Index.vue'],
        () => require('@/views/NotFound/Index.vue'),
        'not-found'
      )
  },
  {
    path: '*',
    redirect: '/404'
  }
]

// 2.6.0 新增，让一个对象可响应，通过操作 meta 来 控制页面访问等权限操作
routes.forEach((route: RouteConfig) => Vue.observable(route.meta))

const routerOptions: RouterOptions = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(
    to: Route,
    from: Route,
    savedPosition: void | { x: number; y: number }
  ) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition || to.meta.keepAlive) {
      return savedPosition
    }

    return { x: 0, y: 0 }

    // 异步滚动操作
    // return new Promise(resolve => setTimeout(() => resolve({ x: 0, y: 0 }), 10))
  }
}

const router = new VueRouter(routerOptions)

const appendCDNForRoute = async (to: Route): Promise<void> => {
  const cdn = to.meta.cdn

  if (cdn && !cdn.completed) {
    await appendResourceToHeadElement(cdn.css, cdn.js)
    cdn.completed = true
  }
}

const setDocumentTitleByRoute = (to: Route): void => {
  const { title } = to.meta

  if (title) {
    document.title =
      to.name === 'Index' ? titleSuffix : title + ' - ' + titleSuffix
  }
}

// 路由加载前
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  pendingRequest.forEach(request => request['cancel']())

  setDocumentTitleByRoute(to)

  if (isProduction) {
    await appendCDNForRoute(to)
  }

  next()
})

// 异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面
router.onError((error: Error) => {
  const pattern = /Loading chunk .+ failed/g
  const isChunkLoadFailed = pattern.test(error.message)
  const pending = router['history'].pending

  isChunkLoadFailed && pending && router.replace(pending.fullPath)
})

export default router
