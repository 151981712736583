var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"tab g-df-vc"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"g-por",class:{ active: index === _vm.active },on:{"click":function($event){return _vm.change(index, _vm.params)}}},[(typeof item === 'object')?[(item.routerName)?_c('router-link',{attrs:{"to":{
          name: item.routerName,
          params: item.params || {},
          query:
            // 往路由中注入一个参数，动态更改路由参数值 触发 跳转同名路由
            _vm.$route.name === item.routerName && _vm.force
              ? Object.assign({}, (item.query || {}),
                  {_time: new Date().getTime() / 1000})
              : item.query || {}
        },"target":item.target || '_self'}},[_c('TabItem',_vm._b({attrs:{"item":item}},'TabItem',_vm.$attrs,false)),(item.gif)?_c('img',{staticClass:"tab-gif",attrs:{"src":require("@viewsImg/index/tip.gif"),"alt":""}}):_vm._e()],1):(item.url)?_c('a',{attrs:{"href":item.url,"target":item.target || '_self'}},[_c('TabItem',_vm._b({attrs:{"item":item}},'TabItem',_vm.$attrs,false))],1):(item.dir !== undefined)?_c('IconSort',{attrs:{"text":item.name || item.text,"dir":item.dir}}):_c('TabItem',_vm._b({attrs:{"item":item,"isActive":index === _vm.active}},'TabItem',_vm.$attrs,false))]:[_c('span',[_vm._v(_vm._s(item))])]],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }