import { DirectiveBinding } from 'vue/types/options'

const undrag = function(el: HTMLElement) {
  el.classList.add('g-usn')
  el.setAttribute('unselectable', 'on')
  el.addEventListener(
    'selectstart',
    function() {
      return false
    },
    false
  )
}

export default [
  {
    name: 'undrag',
    handler: {
      inserted(el: HTMLElement, { arg }: DirectiveBinding) {
        if (arg === 'wrap') {
          const div = document.createElement('div')

          undrag(div)

          div.appendChild(el.cloneNode())
          el.parentNode?.replaceChild(div, el)
        } else {
          undrag(el)
        }
      }
    }
  },
  {
    name: 'class',
    handler: {
      inserted: function(el: HTMLElement, binding: any) {
        el.style.opacity = '0'
        // 聚焦元素
        binding.addClass = () => {
          const { top } = el.getBoundingClientRect()
          const h =
            document.documentElement.clientHeight || document.body.clientHeight

          if (top < h - 100) {
            //减去200刚好，否则刚露头就执行
            el.classList.add(binding.value)

            if (binding.addClass) {
              window.removeEventListener('scroll', binding.addClass)
            }
          } else {
            el.classList.remove(binding.value)
          }
        }

        window.addEventListener('scroll', binding.addClass, true)

        binding.addClass()
      },
      unbind: function(el: HTMLElement, binding: any) {
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
  }
]
