interface IntDownload {
  name: string
  icon: string
  download: string
  type: string
}

export const downloadList: IntDownload[] = [
  {
    name: 'Chrome浏览器',
    icon: require('@viewsImg/plugs/small/chrome.png'),
    download: 'zip',
    type: '谷歌'
  },
  {
    name: '360安全浏览器',
    icon: require('@viewsImg/plugs/small/360security.png'),
    download: 'crx',
    type: '360ee'
  },
  {
    name: '360极速浏览器',
    icon: require('@viewsImg/plugs/small/360speed.png'),
    download: 'crx',
    type: '360se'
  },
  {
    name: '猎豹浏览器',
    icon: require('@viewsImg/plugs/small/cheetah.png'),
    download: 'zip',
    type: '猎豹'
  },
  {
    name: '搜狗高速浏览器',
    icon: require('@viewsImg/plugs/small/sogou.png'),
    download: 'crx',
    type: '搜狗'
  },
  {
    name: 'Edge浏览器',
    icon: require('@viewsImg/plugs/small/edge.png'),
    download: 'zip',
    type: 'Edg'
  },
  {
    name: 'QQ浏览器',
    icon: require('@viewsImg/plugs/small/qq.png'),
    download: 'crx',
    type: 'QQ'
  },
  {
    name: '2345加速浏览器',
    icon: require('@viewsImg/plugs/small/2345acceleration.png'),
    download: 'crx',
    type: '2345'
  }
]

export const isMobile = () => {
  const userAgentInfo = navigator.userAgent

  const mobileAgents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]

  let mobile_flag = false

  //根据userAgent判断是否是手机
  for (let v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true
      break
    }
  }

  const screen_width = window.screen.width
  const screen_height = window.screen.height

  //根据屏幕分辨率判断是否是手机
  if (screen_width < 500 && screen_height < 800) {
    mobile_flag = true
  }

  return mobile_flag
}

export const checkBrowser = () => {
  let browserType = null

  if (isMobile()) {
    browserType = '谷歌'
    return browserType
  }
  const ua = window.navigator.userAgent.toLocaleLowerCase()

  if (ua.match(/edg/) != null) {
    browserType = 'Edg'
  } else if (ua.match(/metasr/) != null) {
    browserType = '搜狗'
  } else if (
    ua.match(/tencenttraveler/) != null ||
    ua.match(/qqbrowse/) != null
  ) {
    browserType = 'QQ'
  } else if (ua.match(/chrome/) != null) {
    const plugins = window.navigator.plugins

    const mime = (option: string, value: string) => {
      return Array.from(plugins).some((item: {}) => {
        return item[option].indexOf(value) !== -1
      })
    }

    const isgo = mime('name', 'Chrome')

    if (window['liebao']) {
      browserType = '猎豹'
    } else if (window['chrome']['adblock2345']) {
      browserType = '2345'
    } else if (!isgo && plugins['Jule game player version 3']) {
      browserType = plugins['Alipay Security Control 3'] ? '360se' : '360ee'
    } else {
      browserType = '谷歌'
    }
  } else {
    browserType = '谷歌'
  }
  return browserType
}

export const openDownload = (type: string) => {
  const zip =
    process.env.VUE_APP_API_URL + '/plugin/download?t=' + new Date().getTime()
  const crx =
    process.env.VUE_APP_API_URL +
    '/plugin/download?crx=1&t=' +
    new Date().getTime()
  // 链接对象地址
  const objectUrl = type === 'zip' ? zip : crx
  // 使用 a 标签进行下载
  const a = document.createElement('a')

  // 默认隐藏
  a.style.display = 'none'
  // 下载链接
  a.href = objectUrl

  // 添加到 body 标签中
  document.body.appendChild(a)

  // 程序触发 a 标签点击事件，进行下载
  a.click()

  // 下载完成移除 a 标签
  document.body.removeChild(a)
}
