export const original = {
  qq: 'qq',
  wechat: 'wechat',
  hdk: 'hdk',
  dtk: 'dtk',
  sina: 'sina',
  douyin: 'douyin',
  ks: 'ks',
  xhs: 'xhs',
  coupon: 'coupon'
}

export const searchTab = {
  [original.qq]: 'QQ',
  [original.wechat]: '微信',
  [original.sina]: '微博',
  // [original.douyin]: '抖音',
  [original.hdk]: '好单库',
  [original.dtk]: '大淘客'
}

export const promoteSummary = {
  [original.qq]: 'QQ渠道',
  [original.wechat]: '微信渠道',
  [original.sina]: '微博渠道',
  // [original.douyin]: '抖音渠道',
  [original.hdk]: '好单库渠道',
  [original.dtk]: '大淘客渠道'
  // [original.coupon]: '优惠券数'
}

export const gatherImg = {
  [original.qq]: require('@globalImg/channel/qq.png'),
  [original.wechat]: require('@globalImg/channel/wechat.png'),
  [original.sina]: require('@globalImg/channel/sina.png'),
  [original.douyin]: require('@globalImg/channel/douyin.png'),
  [original.hdk]: require('@globalImg/channel/hdk.png'),
  [original.dtk]: require('@globalImg/channel/dtk.png'),
  [original.xhs]: require('@globalImg/channel/xhs.png'),
  [original.ks]: require('@globalImg/channel/ks.png')
}

export const couponImg = {
  [original.qq]: require('@globalImg/coupon-qq.png'),
  [original.wechat]: require('@globalImg/coupon-wechat.png'),
  [original.sina]: require('@globalImg/coupon-sina.png'),
  [original.hdk]: require('@globalImg/coupon-hdk.png'),
  [original.dtk]: require('@globalImg/coupon-dtk.png')
}

export const modal = {
  [original.qq]: {
    src: require('@viewsImg/promote-record/qq.png'),
    title: 'QQ传播渠道'
  },
  [original.wechat]: {
    src: require('@viewsImg/promote-record/wechat.png'),
    title: '微信传播渠道'
  },
  [original.sina]: {
    src: require('@viewsImg/promote-record/sina.png'),
    title: '微博传播渠道'
  },
  [original.douyin]: {
    src: require('@viewsImg/promote-record/douyin.png'),
    title: '抖音传播渠道'
  },
  [original.hdk]: {
    src: require('@viewsImg/promote-record/hdk.png'),
    title: '好单库传播渠道'
  },
  [original.dtk]: {
    src: require('@viewsImg/promote-record/dtk.png'),
    title: '大淘客传播渠道'
  }
}

export const product = {
  [original.qq]: {
    src: require('@viewsImg/promote-record/product-qq.png'),
    title: 'Q群'
  },
  [original.wechat]: {
    src: require('@viewsImg/promote-record/product-wechat.png'),
    title: '微信群'
  },
  [original.sina]: {
    src: require('@viewsImg/promote-record/product-sina.png'),
    title: '微博'
  },
  // [original.douyin]: {
  //   src: require('@viewsImg/promote-record/product-douyin.png'),
  //   title: '抖音传播数'
  // },
  [original.hdk]: {
    src: require('@viewsImg/promote-record/product-hdk.png'),
    title: '好单库'
  },
  [original.dtk]: {
    src: require('@viewsImg/promote-record/product-dtk.png'),
    title: '大淘客'
  }
}
