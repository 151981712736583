import Vue from 'vue'

import ELEMENT from 'element-ui'
Vue.use(ELEMENT)

// 图片懒加载，使用于大图、列表图
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@archImg/loading-fail.png'),
  loading: require('@archImg/loading-lazy.gif'),
  attempt: 2,
  adapter: {
    // error(config: {}) {
    //   config['el'].style.maxWidth = '268px'
    //   config['el'].style.maxHeight = '268px'
    // }
  }
})

import Message from '@archUtils/message'
import Loading from '@archUtils/loading'

Vue.prototype.$message = new Message()
Vue.prototype.$loading = new Loading()

// event bus
Vue.prototype.$bus = new Vue()
