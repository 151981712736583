import { requestByGet } from '@/http'

export const getChannelList = (data?: {}, other?: {}) => {
  return requestByGet('/public/channel', data, other)
}

export const getChannelListCount = (data?: {}, other?: {}) => {
  return requestByGet('/public/channelStat', data, other)
}

export const getChannelSummaries = (data?: {}, other?: {}) => {
  return requestByGet('/public/count', data, other)
}

export const getShopScore = (data?: {}, other?: {}) => {
  return requestByGet('/seller/info', data, other)
}

export const getGatherChannelHistory = (data?: {}, other?: {}) => {
  return requestByGet('/promotion/sourcehistory', data, other)
}

export const getGatherCoupons = (data?: {}, other?: {}) => {
  return requestByGet('/coupon/list', data, other)
}
