import { render, staticRenderFns } from "./Radios.vue?vue&type=template&id=70e2d461&scoped=true&"
import script from "./Radios.vue?vue&type=script&lang=ts&"
export * from "./Radios.vue?vue&type=script&lang=ts&"
import style0 from "./Radios.vue?vue&type=style&index=0&id=70e2d461&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e2d461",
  null
  
)

export default component.exports