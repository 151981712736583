import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './register'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')
