import { render, staticRenderFns } from "./Play.vue?vue&type=template&id=e1a42d7c&scoped=true&"
import script from "./Play.vue?vue&type=script&lang=ts&"
export * from "./Play.vue?vue&type=script&lang=ts&"
import style0 from "./Play.vue?vue&type=style&index=0&id=e1a42d7c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1a42d7c",
  null
  
)

export default component.exports